<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <!-- <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div> -->

  <div
    style="
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(32px);
      z-index: 9999;
    "
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pt-8
      px-8
      overflow-y-auto
      w-full
      text-black
      flex flex-col
      h-full
      fixed
    "
  >
    <div class="relative">
      <div class="absolute close-button top-0 right-0">
        <button
          v-if="LightboxModalVisible == false"
          @click.prevent="$emit('overlayClicked')"
          class="border cursor-pointer border-black top-0 right-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>

    <div class="flex z-50">
      <div class="w-1/3 leading-loose mt-2">
        <div
          class="
            sm:max-w-lg
            w-full
            p-10
            bg-white
            rounded-xl
            border border-gray-100
            shadow-xl
            z-10
          "
        >
          <div class="text-center">
            <h2 class="mt-5 text-3xl font-bold text-gray-900">Image Upload</h2>
            <p class="mt-2 text-sm text-gray-400">
              Please upload your item image files below.
            </p>
          </div>
          <form class="mt-8 space-y-3" action="#" method="POST">
            <!-- <div class="grid grid-cols-1 space-y-2">
              <label class="text-sm font-bold text-gray-500 tracking-wide"
                >Title</label
              >
              <input
                class="
                  text-base
                  p-2
                  border border-gray-300
                  rounded-lg
                  focus:outline-none
                  focus:border-indigo-500
                "
                type=""
                placeholder="Rear View"
              />
            </div> -->
            <div class="grid grid-cols-1 space-y-2">
              <label class="text-sm font-bold text-gray-500 tracking-wide"
                >Attach Document</label
              >
              <div class="flex items-center justify-center w-full">
                <div
                  class="
                    flex flex-col
                    rounded-lg
                    border-4 border-dashed
                    w-full
                    h-60
                    p-10
                    group
                    text-center
                  "
                >
                  <div
                    @paste="onPaste"
                    class="
                      h-full
                      w-full
                      text-center
                      flex flex-col
                      items-center
                      justify-center
                      items-center
                    "
                  >
                    <div class="flex flex-auto max-h-48 w-2/5 mx-auto">
                      <svg
                        class="mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#b8b8b8"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <rect x="3" y="3" width="18" height="18" rx="2" />
                        <circle cx="8.5" cy="8.5" r="1.5" />
                        <path d="M20.4 14.5L16 10 4 20" />
                      </svg>
                    </div>
                    <p class="pointer-none text-gray-500">
                      Click and paste (Ctrl-v) your files here
                      <br />
                      or
                      <label
                        class="text-blue-600 cursor-pointer hover:underline"
                        >select a file
                        <input
                          multiple
                          @change="onFileSelect"
                          ref="fileInput"
                          type="file"
                          class="hidden"
                        />
                      </label>
                      from your computer
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-sm text-gray-300">
              <span>File types: JPEG, JPG, PNG, GIF</span>
            </p>
            <div>
              <button
                @click.prevent="handleSave"
                class="
                  my-5
                  w-full
                  flex
                  justify-center
                  bg-gray-500
                  text-gray-100
                  p-4
                  rounded-full
                  tracking-wide
                  font-semibold
                  focus:outline-none
                  focus:shadow-outline
                  hover:bg-gray-600
                  shadow-lg
                  cursor-pointer
                  transition
                  ease-in
                  duration-300
                "
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="w-2/3 ml-4 py-4">
        <h1 class="font-bold text-xl">UPLOADED FILES</h1>

        <div>
          <div
            class="
              grid grid-cols-1
              md:grid-cols-2
              lg:grid-cols-4
              md:gap-x-2
              xl-grid-cols-2
              gap-y-2 gap-x-2
              mt-2
            "
          >
            <div
              v-for="(url, i) in imageUrls"
              :key="i"
              class="
                container
                mx-auto
                hover:shadow-lg
                rounded-lg
                max-w-md
                hover:shadow-2xl
                transition
                duration-300
              "
            >
              <div class="relative">
                <div class="absolute right-0 p-2">
                  <button @click="handleDeleteFile(i)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="white"
                      stroke="#ff0000"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                  </button>
                </div>
              </div>
              <img
                @click="(activeImage = i), (LightboxModalVisible = true)"
                :src="url"
                class="rounded-lg h-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
  <LightboxModal
    @lightboxOverlayClicked="LightboxModalVisible = false"
    :isVisible="LightboxModalVisible"
    :images="imageUrls"
    :activeIndex="activeImage"
    @activeChanged="onActiveImageChange"
  >
  </LightboxModal>
</template>

<script>
import alerts from "@/utils/alert-types";

import LightboxModal from "@/components/modules/vendor-quotes/LightboxModal";

export default {
  name: "ImageUploadModal",
  props: ["isVisible", "fileList"],
  emits: ["overlayClicked", "lightboxOverlayClicked", "selected"],
  data() {
    return {
      LightboxModalVisible: false,
      activeImage: 0,
      files: [],
    };
  },
  watch: {
    fileList(newVal) {
      if (!newVal.length) this.files = [];
    },
  },
  methods: {
    onActiveImageChange(value) {
      this.activeImage = value;
    },
    addFile() {
      this.$emit("overlayClicked");
    },
    onPaste(event) {
      if (this.isVisible) {
        event.preventDefault();
        const fileList = event.clipboardData.files;

        const files = [];

        if (fileList.length) {
          for (const key in fileList) {
            if (typeof fileList[key] == "object") {
              if (fileList[key].type) {
                const allowed =
                  fileList[key].type === "image/jpeg" ||
                  fileList[key].type === "image/jpg" ||
                  fileList[key].type === "image/png" ||
                  fileList[key].type === "image/gif";

                if (allowed) {
                  files.push(fileList[key]);
                } else {
                  this.$store.dispatch("addAlert", {
                    ...alerts.danger,
                    alert: "one of the files you selected is not allowed!",
                  });
                }
              }
            }
          }
        }

        this.files.push(...files);
      }
    },
    onFileSelect(e) {
      let files = [];

      if (e.target.files.length) {
        const fileList = e.target.files;

        for (const key in fileList) {
          if (typeof fileList[key] == "object") {
            if (fileList[key].type) {
              const allowed =
                fileList[key].type === "image/jpeg" ||
                fileList[key].type === "image/jpg" ||
                fileList[key].type === "image/png" ||
                fileList[key].type === "image/gif";

              if (allowed) {
                files.push(fileList[key]);
              } else {
                this.$store.dispatch("addAlert", {
                  ...alerts.danger,
                  alert: "one of the files you selected is not allowed!",
                });
              }
            }
          }
        }
        this.files.push(...files);
      }

      this.$refs.fileInput.value = null;
    },
    handleSave() {
      this.$emit("selected", this.files);
      this.$emit("overlayClicked");
    },
    handleDeleteFile(i) {
      this.files = this.files.filter((f, ind) => ind !== i);
      this.$emit("selected", this.files);
    },
  },
  computed: {
    imageUrls() {
      this.files;
      const urls = [];
      this.files.forEach((file) => {
        urls.push(URL.createObjectURL(file));
      });

      return urls;
    },
  },
  components: {
    LightboxModal,
  },
};
</script>

 

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}

.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}
.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.close-button {
  z-index: 9999;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}
</style>

