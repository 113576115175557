<template>
  <div class="table-content__parent">
    <NewItemModal
      :isVisible="newItemVisible"
      @overlayClicked="newItemVisible = false"
      @itemAdded="addItem"
    ></NewItemModal>
    <!-- Vendor Header (This needs to be a toggle, dropdown to show the table section below this div) -->
    <div style="background-color: #212121" class="py-3.5">
      <div class="flex justify-between items-center">
        <ul
          class="block w-full flex items-center text-xs text-white vendor-table-header-left"
        >
          <li class="px-4">
            <span class="text-gray-300">ID:</span>{{ vendor.number }}
          </li>
          <li class="px-4">
            <span class="text-gray-300">COMPANY:</span> {{ vendor.company }}
          </li>
          <li class="px-4">
            <span class="text-gray-300">NAME:</span> {{ vendor.contactName }}
          </li>
          <li class="px-4">
            <span class="text-gray-300">EMAIL:</span> {{ vendor.email }}
          </li>
          <li class="px-4">
            <span class="text-gray-300">CONTACT:</span>
            {{ vendor.contactNumber }}
          </li>
        </ul>

        <ul
          class="block flex items-center text-xs text-white vendor-table-header-right"
        >
          <li class="cursor-pointer">
            <button @click="fileUploadModalVisible = true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="white"
                stroke="none"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 384 512"
              >
                <path
                  d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                />
              </svg>
            </button>
          </li>
          <li class="pl-12">
            <button
              v-if="$store.getters.currentUser"
              :disabled="
                !$store.getters.currentUser.permissions.includes(
                  'LOCK_INQUIRIES'
                )
              "
              :class="{
                'cursor-pointer hover:opacity-70': $store.getters.currentUser.permissions.includes(
                  'LOCK_INQUIRIES'
                ),
                'cursor-not-allowed': !$store.getters.currentUser.permissions.includes(
                  'LOCK_INQUIRIES'
                ),
              }"
              @click="
                (vendorQuote.isLocked = !vendorQuote.isLocked), handleEdit()
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                class="hover:opacity-70"
                :stroke="vendorQuote.isLocked === true ? 'red' : 'green'"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
              </svg>
            </button>
          </li>

          <li class="px-4">
            <!-- <div class="relative inline-block text-left dropdown">
              <span class="rounded-md shadow-sm"
                ><button
                  class="
                    inline-flex
                    justify-center
                    w-full
                    px-3
                    py-1
                    text-sm
                    font-medium
                    leading-5
                    text-gray-100
                    transition
                    duration-150
                    ease-in-out
                    border border-gray-300
                    rounded-md
                    hover:text-gray-500
                    focus:outline-none
                    focus:border-gray-500
                    focus:shadow-outline-blue
                    active:bg-gray-50
                    active:text-gray-800
                  "
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                  aria-controls="headlessui-menu-items-117"
                >
                  <span class="text-green-500">APPROVED</span>
                  <svg
                    class="w-5 h-5 -mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg></button
              ></span>
              <div
                class="
                  opacity-0
                  invisible
                  dropdown-menu
                  transition-all
                  duration-300
                  transform
                  origin-top-right
                  -translate-y-2
                  scale-95
                "
              >
                <div
                  class="
                    absolute
                    right-0
                    w-40
                    mt-2
                    origin-top-right
                    bg-gray-900
                    border border-gray-600
                    divide-y divide-gray-100
                    rounded-md
                    shadow-lg
                    outline-none
                  "
                  aria-labelledby="headlessui-menu-button-1"
                  id="headlessui-menu-items-117"
                  role="menu"
                >
                  <div class="py-1 font-medium">
                    <a
                      href="javascript:void(0)"
                      tabindex="0"
                      class="
                        text-green-500
                        hover:bg-gray-800
                        flex
                        justify-between
                        w-full
                        px-4
                        py-1
                        text-sm
                        leading-5
                        text-left
                      "
                      role="menuitem"
                    >
                      APPROVED</a
                    >
                    <a
                      href="javascript:void(0)"
                      tabindex="1"
                      class="
                        text-yellow-400
                        hover:bg-gray-800
                        flex
                        justify-between
                        w-full
                        px-4
                        py-1
                        text-sm
                        leading-5
                        text-left
                      "
                      role="menuitem"
                      >ON HOLD</a
                    >

                    <a
                      href="javascript:void(0)"
                      tabindex="2"
                      class="
                        text-red-500
                        hover:bg-gray-800
                        flex
                        justify-between
                        w-full
                        px-4
                        py-1
                        text-sm
                        leading-5
                        text-left
                      "
                      role="menuitem"
                      >REJECTED</a
                    >
                  </div>
                </div>

              </div>
            </div> -->

            <select
              :class="{
                'text-green-500': vendorQuote.status == 'approved',
                'text-yellow-500': vendorQuote.status == 'onHold',
                'text-red-500': vendorQuote.status == 'rejected',
              }"
              :disabled="vendorQuote.isLocked"
              v-model="vendorQuote.status"
              class="border border-white p-1 text-xs bg-black color-white rounded"
            >
              <option>Select Status</option>
              <option value="approved">APPROVED</option>
              <option value="onHold">ON HOLD</option>
              <option value="rejected">REJECTED</option>
              <option value="sample">SAMPLE</option>
            </select>
          </li>

          <li>
            <button
              class="pl-8 px-3"
              :disabled="vendorQuote.isLocked"
              :class="{
                'cursor-pointer hover:opacity-70': !vendorQuote.isLocked,
                'cursor-not-allowed': vendorQuote.isLocked,
              }"
              @click="newItemVisible = true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
            </button>
            <!-- Add New Item Button -->
          </li>

          <!-- App Edit Button -->
          <li>
            <button
              :class="{
                'cursor-pointer hover:opacity-70':
                  !vendorQuote.isLocked && saved,
                'cursor-not-allowed': vendorQuote.isLocked || !saved,
              }"
              :disabled="vendorQuote.isLocked || !saved"
              @click="editVendorVisible = true"
              class="px-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
              </svg>
            </button>
          </li>
          <!-- Delete Button -->
          <li>
            <button
              :class="{
                'cursor-pointer hover:opacity-70': !vendorQuote.isLocked,
                'cursor-not-allowed': vendorQuote.isLocked,
              }"
              :disabled="vendorQuote.isLocked"
              @click="deleteConfirmVisible = true"
              class="cursor-pointer px-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="red"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path
                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                ></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </button>
          </li>
          <!-- Toggle Burron -->
          <li
            @click="quoteVisible = !quoteVisible"
            :class="{
              'table-content-icon__hidden': !quoteVisible,
              'table-content-icon__visible': quoteVisible,
            }"
            class="table-content-icon cursor-pointer px-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M18 15l-6-6-6 6" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
    <!-- / Table Header -->
    <div
      :style="{
        maxHeight: tableContentMaxHeight,
      }"
      class="table-content flex overflow-x-auto hide-scroll-bar text-left font-normal text-xs w-full"
    >
      <div
        ref="tableContentContainer"
        class="shadow rounded border-b border-gray-300"
      >
        <table style="width: 2000px" class="min-w-full bg-white">
          <!-- Vendor Table Header - margins need to be adjusted a little and adding action link -->
          <thead class="bg-gray-100 text-gray-800">
            <tr style="width: 100%">
              <th class="font-normal py-0.5 pl-4 border">ITEM DETAILS</th>

              <th class="font-normal text-right py-0.5 border">QTY</th>
              <th class="font-normal text-right py-0.5 border">
                UNIT EXW <br />F/C
              </th>
              <th class="font-normal text-right py-0.5 border">
                TTL EXW <br />F/C
              </th>
              <th class="font-normal text-right py-0.5 border">UNIT EXW</th>

              <th class="font-normal text-right py-0.5 border">TTL EXW</th>
              <th class="font-normal text-right py-0.5 border">FRT. %</th>
              <th class="font-normal text-right py-0.5 border">
                FRT. <br />AMNT
              </th>
              <th class="font-normal text-right py-0.5 border">FRT. TTL</th>
              <th class="font-normal text-right py-0.5 border">DUTY %</th>
              <th class="font-normal text-right py-0.5 border">
                DUTY <br />AMNT
              </th>
              <th class="font-normal text-right py-0.5 border">
                DUTY <br />TTL
              </th>
              <th class="font-normal text-right py-0.5 border">
                UNIT <br />COST
              </th>
              <th class="font-normal text-right py-0.5 border">
                TTL <br />COST
              </th>
              <th class="font-normal py-0.5 text-right border">
                MARK <br />UP %
              </th>
              <th class="font-normal py-0.5 text-right border">
                UNIT <br />PROFIT
              </th>
              <th class="font-normal py-0.5 text-right border">
                TTL<br />PROFIT
              </th>
              <th class="font-normal py-0.5 text-right border">
                UNIT SELLING<br />PRICE
              </th>
              <th class="font-normal py-0.5 text-right border">
                TTL SELLING<br />PRICE
              </th>
              <th class="font-normal py-0.5 border"></th>
            </tr>
          </thead>
          <tbody class="text-gray-900">
            <!-- Vendor Items Table -->
            <!-- Table Row 1 -->
            <tr v-for="(item, i) in vendorQuote.items" :key="item._id">
              <td class="w-1/5 text-left py-0.5 px-4 border relative">
                <input
                  :disabled="vendorQuote.isLocked"
                  type="text"
                  v-model="item.itemId"
                  v-if="item.itemId"
                  class="block bg-white outline-none w-full"
                />
                <!-- <p>{{ item.itemId }}</p> -->
                <input
                  :disabled="vendorQuote.isLocked"
                  type="text"
                  v-model="item.modelOrPart"
                  v-if="item.modelOrPart"
                  class="block bg-white outline-none w-full"
                />
                <input
                  :disabled="vendorQuote.isLocked"
                  type="text"
                  v-model="item.description"
                  v-if="item.description"
                  class="block bg-white outline-none w-full"
                />
                <button
                  @click="
                    (selectedItemIndex = i), (editImageModalVisible = true)
                  "
                  class="cursor-pointer absolute right-0 top-4 mr-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <rect x="3" y="3" width="18" height="18" rx="2" />
                    <circle cx="8.5" cy="8.5" r="1.5" />
                    <path d="M20.4 14.5L16 10 4 20" />
                  </svg>
                </button>
              </td>

              <td class="text-center py-3 border">
                <input
                  :disabled="vendorQuote.isLocked"
                  class="w-12 justify-center px-1 py-0.5 p-1 border-gray-900 bg-gray-100 border-2 rounded border"
                  v-model="item.quantity"
                  type="number"
                />
              </td>
              <td class="w-20 text-center py-3 border">
                <input
                  :disabled="vendorQuote.isLocked"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 p-1 border-gray-900 border-2 rounded border"
                  type="number"
                  v-model="item.price"
                />
              </td>
              <!-- APP TTL EXW F/C COLUMN -->
              <td class="w-14 text-right py-3 px-4 border">
                {{ (item.price * item.quantity).toFixed(2) }}
              </td>
              <!-- APP UNIT EXW COLUMN -->
              <td
                v-if="vendorQuote.localCurrency"
                class="w-20 text-right py-3 px-4 border"
              >
                {{ (item.price * vendorQuote.localCurrency.value).toFixed(2) }}
              </td>
              <!-- APP TTL EXW COLUMN -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    item.price *
                    vendorQuote.localCurrency.value *
                    item.quantity
                  ).toFixed(2)
                }}
              </td>
              <!-- APP FRT. % -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    ((((item.price *
                      vendorQuote.localCurrency.value *
                      item.quantity) /
                      TTLEXW) *
                      FRT_REF) /
                      FRT_REF) *
                    100
                  ).toFixed(2) == 'NaN'
                    ? 0
                    : (
                        ((((item.price *
                          vendorQuote.localCurrency.value *
                          item.quantity) /
                          TTLEXW) *
                          FRT_REF) /
                          FRT_REF) *
                        100
                      ).toFixed(2)
                }}
              </td>
              <!-- APP FRT. AMNT -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    (((item.price *
                      vendorQuote.localCurrency.value *
                      item.quantity) /
                      TTLEXW) *
                      FRT_REF) /
                    item.quantity
                  ).toFixed(2)
                }}
              </td>
              <!-- APP FRT. TTL -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    ((item.price *
                      vendorQuote.localCurrency.value *
                      item.quantity) /
                      TTLEXW) *
                    FRT_REF
                  ).toFixed(2)
                }}
              </td>
              <td class="w-20 text-center py-3 border">
                <!-- APP DUTY % -->
                <input
                  :disabled="vendorQuote.isLocked"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 p-1 border-gray-900 border-2 rounded border"
                  type="number"
                  v-model="item.dutyPercentage"
                />
                %
              </td>
              <!-- APP DUTY AMNT -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    (item.dutyPercentage / 100) *
                    (item.price * vendorQuote.localCurrency.value).toFixed(2)
                  ).toFixed(2)
                }}
              </td>
              <!-- APP DUTY TTL -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    (item.dutyPercentage / 100) *
                    (item.price * vendorQuote.localCurrency.value).toFixed(2) *
                    item.quantity
                  ).toFixed(2)
                }}
              </td>
              <!-- APP UNIT COST -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    item.price * vendorQuote.localCurrency.value +
                    (((item.price *
                      vendorQuote.localCurrency.value *
                      item.quantity) /
                      TTLEXW) *
                      FRT_REF) /
                      item.quantity +
                    (item.dutyPercentage / 100) *
                      (item.price * vendorQuote.localCurrency.value)
                  ).toFixed(2)
                }}
              </td>
              <!-- APP TTL COST -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    (item.price * vendorQuote.localCurrency.value +
                      (((item.price *
                        vendorQuote.localCurrency.value *
                        item.quantity) /
                        TTLEXW) *
                        FRT_REF) /
                        item.quantity +
                      (item.dutyPercentage / 100) *
                        (item.price * vendorQuote.localCurrency.value)) *
                    item.quantity
                  ).toFixed(2)
                }}
              </td>
              <!-- APP MARK UP % -->
              <td class="w-20 text-center py-3 border">
                <input
                  :disabled="vendorQuote.isLocked"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 p-1 border-gray-900 border-2 rounded border"
                  type="number"
                  v-model="item.markUpPercentage"
                />
                %
              </td>
              <!-- APP UNIT PROFIT -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    item.price *
                    vendorQuote.localCurrency.value *
                    (item.markUpPercentage / 100)
                  ).toFixed(2)
                }}
              </td>
              <!-- TTL PROFIT -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    item.price *
                    vendorQuote.localCurrency.value *
                    (item.markUpPercentage / 100) *
                    item.quantity
                  ).toFixed(2)
                }}
              </td>
              <!-- APP UNIT SELLING PRICE -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    item.price * vendorQuote.localCurrency.value +
                    (((item.price *
                      vendorQuote.localCurrency.value *
                      item.quantity) /
                      TTLEXW) *
                      FRT_REF) /
                      item.quantity +
                    (item.dutyPercentage / 100) *
                      (item.price * vendorQuote.localCurrency.value) +
                    item.price *
                      vendorQuote.localCurrency.value *
                      (item.markUpPercentage / 100)
                  ).toFixed(2)
                }}
              </td>
              <!--  APP TTL SELLING PRICE -->
              <td class="w-20 text-right py-3 px-4 border">
                {{
                  (
                    (item.price * vendorQuote.localCurrency.value +
                      (((item.price *
                        vendorQuote.localCurrency.value *
                        item.quantity) /
                        TTLEXW) *
                        FRT_REF) /
                        item.quantity +
                      (item.dutyPercentage / 100) *
                        (item.price * vendorQuote.localCurrency.value) +
                      item.price *
                        vendorQuote.localCurrency.value *
                        (item.markUpPercentage / 100)) *
                    item.quantity
                  ).toFixed(2)
                }}
              </td>
              <!-- APP ITEM DELETE BUTTON -->
              <td class="w-4 text-right py-3 px-4 border">
                <button @click="removeItem(item._id)" class="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#d40000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>
              </td>
            </tr>
            <!-- Table Row 2 -->
            <tr class="bg-gray-200">
              <td class="w-1/5 text-right py-1 px-4 border">TOTAL ITEMS:</td>
              <td class="w-12 text-left py-1 px-1 border">{{ TTL_ITEMS }}</td>
              <td class="text-right p- border">TTL EXW F/C:</td>

              <td class="w-20 text-right py-1 px-4 border">{{ TTLEXW_FC }}</td>
              <td class="text-right border">TTL EXW:</td>
              <td class="w-20 text-right py-1 px-4 border">{{ TTLEXW }}</td>
              <td class="w-20 text-right py-1 px-4 border"></td>
              <td class="text-right border">TTL FRT:</td>
              <td class="w-20 text-right py-1 px-4 border">{{ TTL_FRT }}</td>
              <td class="w-20 text-right py-1 px-4 border"></td>
              <td class="text-right border">TTL DUTY:</td>
              <td class="w-20 text-right py-1 px-4 border">{{ TTL_DUTY }}</td>
              <td class="text-right border">TTL COST:</td>
              <td class="w-20 text-right py-1 px-4 border">{{ TTL_COST }}</td>
              <td class="w-20 text-right py-1 px-4 border"></td>
              <td class="text-right border">TTL PROFIT:</td>
              <td class="w-20 text-right py-1 px-4 border">{{ TTL_PROFIT }}</td>
              <td class="text-right border">SUB TTL:</td>
              <td class="w-20 text-right py-1 px-4 border">
                {{ TTL_SELLING_PRICE }}
              </td>

              <td class="w-4 text-right py-1 px-4 border"></td>
            </tr>
            <!-- Table Row 3 -->
            <tr class="bg-gray-200">
              <td class="w-1/5 text-right px-4 border"></td>
              <td class="w-12 text-left px-1 border"></td>
              <td class="w-20 text-center border">
                <select
                  :disabled="vendorQuote.isLocked"
                  v-if="vendorQuote.foreignCurrency"
                  v-model="vendorQuote.foreignCurrency.code"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  name="currency"
                  id=""
                >
                  <option
                    v-for="(code, i) in currencies"
                    :key="i"
                    :value="code"
                  >
                    {{ code }}
                  </option>
                </select>
              </td>

              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-center border">
                <select
                  :disabled="vendorQuote.isLocked"
                  v-if="vendorQuote.localCurrency"
                  v-model="vendorQuote.localCurrency.code"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  name="currency"
                >
                  <!-- <option
                    v-for="(code, i) in currencies"
                    :key="i"
                    :value="code"
                  >
                    {{ code }}
                  </option> -->
                  <option value="USD">USD</option>
                  <option value="MVR">MVR</option>
                </select>
              </td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border">FRT. REF:</td>
              <td class="w-20 text-right px-4 border">
                {{ FRT_REF }}
              </td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border">GST AMNT:</td>
              <td class="w-20 text-right px-4 border">
                {{
                  (new Date($props.inquiry.date.replace('Z', '')) >
                  new Date('2023-01-01')
                    ? TTL_SELLING_PRICE * 0.08
                    : TTL_SELLING_PRICE * 0.06
                  ).toFixed(2)
                }}
              </td>

              <td class="w-4 text-right px-4 border"></td>
            </tr>
            <!-- Table Row 4 -->
            <tr class="bg-gray-200">
              <td class="w-1/5 text-right px-4 border"></td>
              <td class="w-12 text-left px-1 border"></td>
              <td class="w-20 text-center border">
                <input
                  type="number"
                  disabled
                  class="w-16 bg-gray-200 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  v-if="vendorQuote.foreignCurrency"
                  v-model="vendorQuote.foreignCurrency.value"
                />
              </td>

              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-center border">
                <input
                  :disabled="vendorQuote.isLocked"
                  type="number"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  v-if="vendorQuote.localCurrency"
                  v-model="vendorQuote.localCurrency.value"
                />
              </td>
              <td class="w-20 text-right px-4 border">INCOTERMS:</td>
              <td class="w-20 text-center border">
                <select
                  :disabled="vendorQuote.isLocked"
                  v-if="vendorQuote.incoTerms"
                  v-model="vendorQuote.incoTerms"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  name="incoterms"
                  id=""
                >
                  <option value="none">None</option>
                  <option value="local">LOCAL</option>
                  <option value="cif">CIF</option>
                  <option value="fob">FOB</option>
                  <option value="cfr">CFR</option>
                  <option value="cnf">CNF</option>
                  <option value="exw">EXW</option>
                </select>
              </td>
              <td class="text-right border">FRT. CHG:</td>
              <td class="w-20 text-center border">
                <input
                  :disabled="vendorQuote.isLocked"
                  type="number"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 text-right rounded border"
                  v-model="vendorQuote.freightCharges"
                />
              </td>

              <td class="w-20 text-left px-4 border">
                <span v-if="vendorQuote.localCurrency">{{
                  vendorQuote.localCurrency.code
                }}</span>
              </td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border">GRAND TTL:</td>
              <td class="w-20 text-right px-4 border">
                {{
                  (
                    TTL_SELLING_PRICE * 1 +
                    (new Date($props.inquiry.date.replace('Z', '')) >
                    new Date('2023-01-01')
                      ? TTL_SELLING_PRICE * 0.08
                      : TTL_SELLING_PRICE * 0.06)
                  ).toFixed(2)
                }}
              </td>

              <td class="w-4 text-right px-4 border"></td>
            </tr>
            <!-- Table Row 5 -->
            <tr class="bg-gray-200">
              <td class="w-1/5 text-right px-4 border"></td>
              <td class="w-12 text-left px-1 border"></td>
              <td class="w-20 text-center border"></td>

              <td class="w-20 text-right font-bold text-red-600 border">
                <span v-if="usdRateEditable">$ RATE</span>
              </td>
              <td class="w-20 text-center border">
                <input
                  type="number"
                  class="w-16 bg-yellow-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  v-if="usdRateEditable"
                  :disabled="vendorQuote.isLocked"
                  v-model="vendorQuote.usdRate"
                />
              </td>
              <td class="w-20 text-right px-4 border">FRT MTHD:</td>
              <td class="w-20 text-center border">
                <select
                  :disabled="vendorQuote.isLocked"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  name="carrier"
                  v-model="vendorQuote.freightMethod"
                >
                  <option value="air">AIR</option>
                  <option value="sea">SEA</option>
                  <option value="courier">CRR</option>
                  <option value="none">NONE</option>
                </select>
              </td>

              <td class="w-20 text-right px-4 border">ADJ</td>
              <td class="w-20 text-center border">
                <input
                  :disabled="vendorQuote.isLocked"
                  type="number"
                  class="w-16 bg-gray-100 text-right justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  v-model="vendorQuote.adjustment"
                />
              </td>
              <td class="w-20 text-left px-4 border">
                <span v-if="vendorQuote.localCurrency">{{
                  vendorQuote.localCurrency.code
                }}</span>
              </td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>

              <td class="w-4 text-right px-4 border"></td>
            </tr>
            <!-- Table Row 6 -->
            <tr class="bg-gray-200">
              <td class="w-1/5 text-right px-4 border"></td>
              <td class="w-12 text-left px-1 border"></td>
              <td class="w-20 text-center border"></td>

              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-center border"></td>
              <td class="w-20 text-right px-4 border">MODE:</td>
              <td class="w-20 text-center border">
                <input
                  :disabled="vendorQuote.isLocked"
                  class="w-16 bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  name="courier"
                  v-model="vendorQuote.courier"
                />
              </td>

              <td class="w-20 text-right px-4 border">HCL</td>
              <td class="w-20 text-center border">
                <input
                  :disabled="vendorQuote.isLocked"
                  type="number"
                  class="w-16 text-right bg-gray-100 justify-center px-1 py-0.5 border-gray-900 border-2 rounded border"
                  v-model="vendorQuote.hcl"
                />
              </td>
              <td class="w-20 text-left px-4 border">
                <span v-if="vendorQuote.localCurrency">{{
                  vendorQuote.localCurrency.code
                }}</span>
              </td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>

              <td class="w-4 text-right px-4 border"></td>
            </tr>
            <!-- Table Row 7 -->
            <tr class="bg-gray-200">
              <td class="w-1/5 text-right px-4 border"></td>
              <td class="w-12 text-left px-1 border"></td>
              <td class="w-20 text-center border"></td>

              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-center border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-center border"></td>

              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-center border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>
              <td class="text-right border"></td>
              <td class="w-20 text-right px-4 border"></td>

              <td class="w-4 text-right px-4 border"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    v-if="!saved"
    class="bg-black justify-center p-3 text-white text-sm flex"
  >
    <span class="py-1">You have unsaved changes! </span>
    <button
      style="color: #cfb47e"
      class="px-2 py-1 mr-2 rounded"
      @click="revertChanges"
    >
      Revert Changes
    </button>
    <button
      style="background-color: #cfb47e"
      @click="handleEdit()"
      class="px-2 py-1 rounded"
    >
      Save Changes
    </button>
  </div>
  <EditVendorTableItemModal
    @vendorEdited="editVendorContact"
    @overlayClicked="editVendorVisible = false"
    :vendor="vendorQuote"
    :isVisible="editVendorVisible"
  />
  <!-- DELETE CONFIRM MODAL -->
  <div
    v-if="deleteConfirmVisible"
    @click="deleteConfirmVisible = false"
    class="confirm-overlay"
  ></div>
  <div
    v-if="deleteConfirmVisible"
    class="delete-confirm text-xl shadow rounded p-10"
  >
    <div>
      Delete vendor
      <span class="font-bold">{{ vendorQuote.company }}?</span>
      <br />
      <span class="inline-block my-4 text-red-700"
        >This action cannot be undone.</span
      >
    </div>
    <div class="flex justify-end mt-6">
      <button
        @click="deleteConfirmVisible = false"
        class="py-2 px-4 bg-green-600 text-white rounded mr-2"
      >
        No
      </button>
      <button
        @click="(deleteConfirmVisible = false), deleteVendorItem()"
        class="py-2 px-4 bg-red-600 text-white rounded"
      >
        Yes
      </button>
    </div>
  </div>
  <!-- / DELETE CONFIRM MODAL -->

  <!-- EDIT IMAGES MODAL -->
  <EditItemImagesModal
    v-if="vendorQuote.items"
    :locked="vendorQuote.isLocked"
    :isVisible="editImageModalVisible"
    @overlayClicked="editImageModalVisible = false"
    :fileList="
      vendorQuote.items &&
      vendorQuote.items.length &&
      selectedItemIndex !== null
        ? vendorQuote.items[selectedItemIndex].files
        : null
    "
    :images="
      vendorQuote.items &&
      vendorQuote.items.length &&
      selectedItemIndex !== null
        ? vendorQuote.items[selectedItemIndex].images
        : null
    "
    @selected="handleItemImageEdit"
  ></EditItemImagesModal>

  <FileUploadModal
    :locked="vendorQuote.isLocked"
    v-if="vendorQuote.files"
    :isVisible="fileUploadModalVisible"
    :documents="vendorQuote.documents"
    :selectedFiles="vendorQuote.files"
    @overlayClicked="fileUploadModalVisible = false"
    @selected="handleDocumentSelect"
  ></FileUploadModal>
</template>

<script>
import currencyCodes from '@/utils/currencies';
import NewItemModal from '@/components/modules/vendor-quotes/NewItemModal';
import EditVendorTableItemModal from '@/components/modules/vendor-quotes/EditVendorTableItemModal';
import EditItemImagesModal from '@/components/modules/vendor-quotes/EditItemImagesModal';
import FileUploadModal from '@/components/base/FileUploadModal';

export default {
  name: 'InquiryVendorTableItem',
  props: ['vendor', 'inquiry'],
  emits: ['editVendor', 'vendorDeleted'],
  data() {
    return {
      fileUploadModalVisible: false,
      selectedItemIndex: null,
      editImageModalVisible: false,
      quoteVisible: false,
      vendorQuote: {},
      currencies: currencyCodes,
      saved: true,
      newItemVisible: false,
      editVendorVisible: false,
      deleteConfirmVisible: false,
      //   To prevent watch from running on init
      init: true,
    };
  },
  methods: {
    handleDocumentSelect(data) {
      this.vendorQuote.files = data.files;

      if (data.deletedFiles && data.deletedFiles.length) {
        data.deletedFiles.forEach((file) => {
          this.vendorQuote.deletedFiles.push(file.file);
          this.vendorQuote.documents = this.vendorQuote.documents.filter(
            (doc) => doc._id !== file._id
          );
        });
      }
      this.handleEdit();
    },
    handleItemImageEdit(data) {
      this.vendorQuote.items[this.selectedItemIndex].files.push(...data.files);
      this.vendorQuote.items[this.selectedItemIndex].images = data.images;
      if (data.deleted && data.deleted.length) {
        this.vendorQuote.deletedFiles.push(...data.deleted);
      }
      this.handleEdit();
    },
    handleEdit() {
      const quote = JSON.parse(JSON.stringify(this.vendorQuote));
      quote.items.forEach((item, i) => {
        if (item.files.length)
          item.files = [...this.vendorQuote.items[i].files];
      });
      quote.files = [...this.vendorQuote.files];
      this.init = true;
      this.$emit('editVendor', quote);
      this.saved = true;
    },
    editVendorContact(vendorQuote) {
      this.setVendorQuote(vendorQuote);
      this.init = true;
      this.$emit('editVendor', this.vendorQuote);
      this.saved = true;
    },
    addItem(item) {
      this.vendorQuote.items.push(item);
    },
    revertChanges() {
      this.init = true;
      this.setVendorQuote(this.$props.vendor);
      this.saved = true;
    },
    removeItem(id) {
      this.vendorQuote.items.forEach((item) => {
        if (item._id === id) {
          if (item.images.length) {
            this.vendorQuote.deletedFiles.push(...item.images);
          }
        }
      });
      this.vendorQuote.items = this.vendorQuote.items.filter(
        (item) => item._id != id
      );
    },
    deleteVendorItem() {
      this.$emit('vendorDeleted', {
        id: this.$props.inquiry._id,
        vendor: this.vendorQuote,
      });
    },
    setVendorQuote(vendor) {
      const vendorQuote = JSON.parse(JSON.stringify(vendor));

      vendorQuote.adjustment = parseFloat(vendorQuote.adjustment).toFixed(2);
      vendorQuote.hcl = parseFloat(vendorQuote.hcl).toFixed(2);
      vendorQuote.freightCharges = parseFloat(
        vendorQuote.freightCharges
      ).toFixed(2);
      vendorQuote.foreignCurrency.value = parseFloat(
        vendorQuote.foreignCurrency.value
      ).toFixed(2);
      vendorQuote.localCurrency.value = parseFloat(
        vendorQuote.localCurrency.value
      ).toFixed(2);

      if (
        vendorQuote.localCurrency.code !== vendorQuote.foreignCurrency.code &&
        vendorQuote.foreignCurrency.code === 'USD'
      ) {
        vendorQuote.usdRate = vendorQuote.localCurrency.value;
      } else if (
        vendorQuote.localCurrency.code !== 'USD' &&
        vendorQuote.foreignCurrency.code !== 'USD'
      ) {
        vendorQuote.usdRate = parseFloat(vendorQuote.usdRate).toFixed(2);
      }

      if (vendorQuote.items && vendorQuote.items.length) {
        vendorQuote.items.forEach((item) => {
          item.price = parseFloat(item.price).toFixed(2);
          if (!item.files) item.files = [];
          if (!item.images) item.images = [];
        });
      } else {
        vendorQuote.items = [];
      }

      if (!vendorQuote.documents) vendorQuote.documents = [];
      vendorQuote.files = [];

      vendorQuote.deletedFiles = [];

      this.vendorQuote = vendorQuote;
    },
  },
  mounted() {
    this.init = true;
    this.setVendorQuote(this.$props.vendor);
    this.$nextTick(() => (this.quoteVisible = true));
  },
  watch: {
    vendorQuote: {
      handler(vendorQuote) {
        vendorQuote;
        if (this.init === true) {
          this.init = false;
          return;
        }
        this.saved = false;
      },
      deep: true,
    },

    vendor: {
      handler(vendor) {
        this.init = true;
        this.setVendorQuote(vendor);
        this.$forceUpdate();
      },
    },
  },
  computed: {
    usdRateEditable() {
      if (
        !this.vendorQuote ||
        !this.vendorQuote.localCurrency ||
        !this.vendorQuote.foreignCurrency
      )
        return false;
      if (
        this.vendorQuote.localCurrency.code !== 'USD' &&
        this.vendorQuote.foreignCurrency.code !== 'USD'
      ) {
        return true;
      } else {
        return false;
      }
    },
    TTL_ITEMS() {
      let total = 0;

      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          item;
          total++;
        });
      }

      return total;
    },
    TTLEXW() {
      let total = 0;
      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          total +=
            item.price * this.vendorQuote.localCurrency.value * item.quantity;
        });
      }
      return total.toFixed(2);
    },
    TTLEXW_FC() {
      let total = 0;
      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          total += item.price * item.quantity;
        });
      }

      return total.toFixed(2);
    },
    FRT_REF() {
      return (
        this.vendorQuote.hcl * 1 +
        this.vendorQuote.adjustment * 1 +
        this.vendorQuote.freightCharges * 1
      ).toFixed(2);
    },

    TTL_FRT() {
      let total = 0;

      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          total +=
            ((item.price *
              this.vendorQuote.localCurrency.value *
              item.quantity) /
              this.TTLEXW) *
            this.FRT_REF;
        });
      }
      return total.toFixed(2);
    },

    TTL_DUTY() {
      let total = 0;

      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          total +=
            (item.dutyPercentage / 100) *
            (item.price * this.vendorQuote.localCurrency.value).toFixed(2) *
            item.quantity;
        });
      }
      return total.toFixed(2) * 1;
    },

    TTL_COST() {
      let total = 0;

      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          total +=
            (item.price * this.vendorQuote.localCurrency.value +
              (((item.price *
                this.vendorQuote.localCurrency.value *
                item.quantity) /
                this.TTLEXW) *
                this.FRT_REF) /
                item.quantity +
              (item.dutyPercentage / 100) *
                (item.price * this.vendorQuote.localCurrency.value)) *
            item.quantity;
        });
      }

      return total.toFixed(2);
    },

    TTL_PROFIT() {
      let total = 0;

      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          const unitPrice = item.price * this.vendorQuote.localCurrency.value;

          const markup =
            unitPrice * (item.markUpPercentage / 100) * item.quantity;

          total += markup;
        });
      }

      return total.toFixed(2);
    },

    TTL_SELLING_PRICE() {
      let total = 0;

      if (this.vendorQuote.items && this.vendorQuote.items.length) {
        this.vendorQuote.items.forEach((item) => {
          total +=
            (item.price * this.vendorQuote.localCurrency.value +
              (((item.price *
                this.vendorQuote.localCurrency.value *
                item.quantity) /
                this.TTLEXW) *
                this.FRT_REF) /
                item.quantity +
              (item.dutyPercentage / 100) *
                (item.price * this.vendorQuote.localCurrency.value) +
              item.price *
                this.vendorQuote.localCurrency.value *
                (item.markUpPercentage / 100)) *
            item.quantity;
        });
      }

      return total.toFixed(2);
    },

    tableContentMaxHeight() {
      this.quoteVisible;
      if (this.$refs.tableContentContainer) {
        if (this.quoteVisible) {
          return this.$refs.tableContentContainer.scrollHeight + 'px';
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },

    // _saved() {

    // },
  },
  components: {
    NewItemModal,
    EditVendorTableItemModal,
    EditItemImagesModal,
    FileUploadModal,
  },
};
</script>

<style lang="scss" scoped>
.table-content {
  transform-origin: top;
  transition: max-height 0.5s;
  overflow-y: hidden;
}

.table-content-icon {
  transition: transform 0.5s ease-in-out;
}

.table-content-icon__hidden {
  transform: rotate(180deg);
}
.table-content-icon__visible {
  transform: rotate(0deg);
}

.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: right;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.vendor-table-header-left {
  @media (max-width: 1326px) {
    font-size: 0.7rem;
    li {
      padding: 0;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &:first-child {
        margin-left: 1rem;
      }
    }
  }
}
.vendor-table-header-right {
  @media (max-width: 1326px) {
    li {
      padding: 0;
      margin-right: 0.5rem;

      &:first-child {
        margin-left: 1rem;
      }
    }
  }
}
</style>
