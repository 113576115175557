<template>
  <div>
    <!-- Vendor Top Bar (Inquiry No/Status/Search/Buttons) -->
    <div
      :style="{ marginLeft: topBarLeftMargin, width: contentAreaWidth }"
      class="
        content-area
        left-0
        mt-14
        w-full
        bg-gradient-to-r
        from-white
        bg-gray-100
        flex
        justify-between
        z-20
        fixed
      "
    >
      <ul class="flex items-center ml-1">
        <div
          class="relative flex items-center text-black text-lg font-bold px-2"
        >
          <svg
            @click="$router.go(-1)"
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2 hover:bg-gray-100 rounded-full px-2 cursor-pointer"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M15 18l-6-6 6-6" />
          </svg>
          <span v-if="inquiry">
            {{ inquiry.branch.code }}{{ inquiry.inquiryId }}
          </span>

          <div
            class="
              text-xs
              ml-2
              font-normal
              border border-gray-900
              rounded-md
              p-1
              uppercase
            "
          >
            {{ inquiry.inquiryStatus }}
          </div>
        </div>

        <div class="relative text-gray-600 ml-8">
          <input
            type="search"
            name="serch"
            placeholder="Search"
            class="
              bg-white
              h-9
              border
              w-96
              px-4
              pr-10
              rounded-lg
              text-sm
              focus:outline-none
            "
          />
          <button type="submit" class="absolute right-0 top-0 mt-2.5 mr-4">
            <svg
              class="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </ul>
      <ul class="flex items-center">
        <li
          @click="newVendorIsVisible = true"
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            items-center
            text-sm
            py-1
            mr-2
            rounded-lg
            px-2
            hover:bg-gray-900
            flex
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 mr-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          Add Vendor
        </li>
      </ul>
    </div>
    <!-- Vendor Top Bar End -->
  </div>
  <NewVendorTableItemModal
    @overlayClicked="newVendorIsVisible = false"
    :isVisible="newVendorIsVisible"
    :inquiry="inquiry"
    @newVendorSaved="handleVendorSave"
  ></NewVendorTableItemModal>
  <div class="inq-table">
    <div class="">
      <!-- Inquiry // Customer Details -->
      <div class="flex w-2/3">
        <div class="w-1/6 text-left">
          <ul class="text-black text-sm py-4 pl-4">
            <li class="">
              <span class="text-gray-700">Customer ID:</span>
            </li>
            <li class="">
              <span class="text-gray-700">Customer:</span>
            </li>
            <li class="">
              <span class="text-gray-700">Name:</span>
            </li>
            <li class="">
              <span class="text-gray-700">Address:</span>
            </li>
            <li class="">
              <span class="text-gray-700">Contact:</span>
            </li>
          </ul>
        </div>
        <div class="w-1/2 text-left">
          <ul class="text-black text-sm py-4 pl-0">
            <li class="">
              <span class="text-gray-700">
                {{ inquiry.customerId ? inquiry.customerId : "-" }}
              </span>
            </li>
            <li class="">
              <span class="text-gray-700">{{ customerName }}</span>
            </li>
            <li class="">
              <span class="text-gray-700">
                {{ inquiry.contactName ? inquiry.contactName : "-" }}
              </span>
            </li>
            <li class="">
              <span class="text-gray-700">
                {{ inquiry.address ? inquiry.address : "-" }}
              </span>
            </li>
            <li class="">
              <span class="text-gray-700">
                {{ inquiry.contactMobile ? inquiry.contactMobile : "-" }}
              </span>
            </li>
          </ul>
        </div>
        <div class="w-1/2 text-left">
          <ul class="text-black text-sm py-4 pl-0">
            <li class="">
              <span class="text-gray-700">
                Description: {{ inquiry.description }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Below DIV closes inq-table CSS Class  -->
  </div>

  <!-- Horizontal Scroll Vendor Data Table -->

  <InquiryVendorTableItem
    @vendorDeleted="deleteVendor"
    v-for="vendor in inquiry.vendors"
    :key="vendor._id"
    :vendor="vendor"
    :inquiry="inquiry"
    @editVendor="handleVendorEdit"
  ></InquiryVendorTableItem>
</template>

<script>
import NewVendorTableItemModal from "@/components/modules/vendor-quotes/NewVendorTableItemModal";
import InquiryVendorTableItem from "@/components/modules/vendor-quotes/InquiryVendorTableItem";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SingleInquiry",

  data() {
    return {
      newVendorIsVisible: false,
    };
  },
  methods: {
    ...mapActions([
      "getSingleInquiry",
      "setLastVisitedInquiry",
      "editVendor",
      "deleteVendor",
    ]),
    overlayClicked() {
      this.isVisible = !this.isVisible;
    },
    handleVendorSave() {
      this.getSingleInquiry(this.$route.params.id);
    },
    async handleVendorEdit(vendor) {
      await this.editVendor({ id: this.inquiry._id, vendor });
      this.getSingleInquiry(this.$route.params.id);
    },
  },
  mounted() {
    this.getSingleInquiry(this.$route.params.id);
  },
  computed: {
    ...mapGetters({ inquiry: "selectedInquiry" }),
    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },
    customerName() {
      if (this.inquiry && this.inquiry.customer) {
        return this.inquiry.customer;
      } else if (this.inquiry && this.inquiry.customerCompany) {
        return this.inquiry.customerCompany.name;
      }
      return "-";
    },
    topBarLeftMargin() {
      this.sidebarCollapsed;

      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },
    contentAreaWidth() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
  },
  beforeRouteLeave(to, from, next) {
    to;
    from;

    this.setLastVisitedInquiry(this.inquiry ? this.inquiry._id : "");
    next();
  },
  components: {
    InquiryVendorTableItem,
    NewVendorTableItemModal,
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}

.sidebar {
  width: 16.5%;
}
.content-area {
  transition: margin-left 0.5s ease-in-out;
}
.inq-table {
  margin-top: 5.9rem;
}

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>