<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <!-- <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div> -->

  <div
    style="
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(32px);
      z-index: 10500;
    "
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pt-8
      px-8
      overflow-y-auto
      w-full
      text-black
      flex flex-col
      h-full
      fixed
    "
  >
    <div class="relative">
      <div style="z-index: 10500" class="absolute close-button top-0 right-0">
        <button
          @click.prevent="$emit('lightboxOverlayClicked')"
          class="border cursor-pointer border-black top-0 right-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>

    <div>
      <div
        class="
          bg-gray-100
          rounded-md
          max-w-4xl
          mx-auto
          relative
          p-2
          sm:p-4
          overflow-hidden
          mt-14
        "
      >
        <button
          v-if="canGoPrevious"
          @click="active -= 1"
          class="
            absolute
            left-5
            top-1/2
            -translate-y-1/2
            bg-gray-100
            rounded-full
            w-11
            h-11
            flex
            justify-center
            items-center
            shadow-md
            z-10
          "
        >
          <svg
            class="w-8 h-8 font-bold text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.5"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </button>

        <button
          v-if="canGoNext"
          @click="active += 1"
          class="
            absolute
            right-5
            top-1/2
            -translate-y-1/2
            bg-gray-100
            rounded-full
            w-11
            h-11
            flex
            justify-center
            items-center
            shadow-md
            z-10
          "
        >
          <svg
            class="w-8 h-8 font-bold text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2.5"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </button>

        <div class="relative lightbox">
          <div class="h-full flex items-center justify-center">
            <img :src="imageUrls[active]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LightboxModal",
  props: ["isVisible", "images", "activeIndex"],
  emits: ["lightboxOverlayClicked", "activeChanged"],
  data() {
    return {
      active: this.activeIndex,
    };
  },
  watch: {
    activeIndex(newVal) {
      this.active = newVal;
    },
    active(newVal) {
      this.$emit("activeChanged", newVal);
    },
  },
  computed: {
    imageUrls() {
      this.activeIndex;
      return this.images.map((image) => {
        if (image.substring(0, 4) === "blob") return image;
        return `${process.env.VUE_APP_API_HOST}/uploads/${image}`;
      });
    },
    canGoPrevious() {
      return this.active - 1 >= 0;
    },
    canGoNext() {
      return this.active + 1 <= this.imageUrls.length - 1;
    },
  },
  methods: {
    viewImage() {
      this.$emit("lightboxOverlayClicked");
    },
  },
};
</script>

 

<style scoped>
.lightbox {
  width: 100%;
  height: 100%;
}
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}

.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}
.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.close-button {
  z-index: 9999;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}
</style>

