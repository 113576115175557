<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 v-if="$props.inquiry" class="text-xl font-bold">
        Add Vendor to {{ $props.inquiry.branch.code
        }}{{ $props.inquiry.inquiryId }}
      </h1>

      <div class="leading-loose">
        <form class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="ID"
              v-model="newVendor.number"
            />
          </div>

          <div class="mt-2">
            <select
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              v-model="vendorId"
            >
              <option disabled value="null">Select Vendor</option>
              <option
                v-for="(vendorContact, i) in vendorContacts"
                :key="i"
                :value="vendorContact._id"
              >
                {{ vendorContact.company }} -
                {{ vendorContact.contactName }}
              </option>
            </select>
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <input
              disabled
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Company"
              v-model="newVendor.company"
            />
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <input
              disabled
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Department"
              v-model="newVendor.department"
            />
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <input
              disabled
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Name"
              v-model="newVendor.contactName"
            />
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <input
              disabled
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Email"
              v-model="newVendor.email"
            />
          </div>
          <div class="inline-block mt-2 w-full pr-1">
            <input
              disabled
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Phone"
              v-model="newVendor.contactNumber"
            />
          </div>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
              @click.prevent="saveVendor"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewVendorTableItemModal",
  props: ["isVisible", "inquiry"],
  emits: ["overlayClicked", "newVendorSaved"],
  data() {
    return {
      newVendor: {
        number: "",
        company: "",
        department: "",
        contactName: "",
        email: "",
        contactNumber: "",
      },
      vendorId: null,
    };
  },
  watch: {
    vendorId(id) {
      const index = this.vendorContacts.findIndex((v) => v._id == id);

      if (index >= 0) {
        this.newVendor.company = this.vendorContacts[index]["company"];
        this.newVendor.contactName = this.vendorContacts[index]["contactName"];
        this.newVendor.department = this.vendorContacts[index]["department"];
        this.newVendor.email = this.vendorContacts[index]["email"];
        this.newVendor.contactNumber =
          this.vendorContacts[index]["contactNumber"];
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions(["addVendor", "getVendorContacts"]),

    async saveVendor() {
      try {
        await this.addVendor({
          id: this.$props.inquiry._id,
          newVendor: { ...this.newVendor, vendorContactId: this.vendorId },
        });
        this.$emit("newVendorSaved");
        this.$emit("overlayClicked");
        this.newVendor = {
          number: "",
          company: "",
          department: "",
          contactName: "",
          email: "",
          contactNumber: "",
        };
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  mounted() {
    this.getVendorContacts({ all: true });
  },
  computed: {
    ...mapGetters(["vendorContacts"]),
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>