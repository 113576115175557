<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Add Item</h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Item ID"
              v-model="newItem.itemId"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Model/Part Number"
              v-model="newItem.modelOrPart"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="number"
              placeholder="Price"
              v-model="newItem.price"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="number"
              placeholder="Quantity"
              v-model="newItem.quantity"
            />
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Description"
              v-model="newItem.description"
            />
          </div>

          <div class="mt-2">
            <button
              class="
                flex
                bg-black
                text-white
                px-2
                py-1
                inline-block
                rounded
                items-center
              "
              @click="ImageUploadVisible = true"
            >
              Image Upload
            </button>
          </div>

          <div class="mt-4">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              :disabled="!canSave"
              :class="{ 'cursor-not-allowed': !canSave }"
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              type="submit"
              @click.prevent="addItem"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->

  <ImageUploadModal
    @overlayClicked="ImageUploadVisible = false"
    :isVisible="ImageUploadVisible"
    :fileList="newItem.files"
    @selected="handleImageSelect"
  >
  </ImageUploadModal>
</template>

<script>
// import { mapActions } from "vuex";

import ImageUploadModal from "@/components/modules/vendor-quotes/ImageUploadModal";

export default {
  name: "NewItemModal",
  props: ["isVisible"],
  emits: ["overlayClicked", "itemAdded"],
  data() {
    return {
      ImageUploadVisible: false,
      newItem: {
        itemId: "",
        modelOrPart: "",
        price: "",
        quantity: "",
        description: "",
        dutyPercentage: 0,
        markUpPercentage: 0,
        files: [],
      },
    };
  },
  computed: {
    canSave() {
      return (
        this.newItem.itemId &&
        this.newItem.price &&
        this.newItem.quantity &&
        this.newItem.description
      );
    },
  },
  methods: {
    addItem() {
      this.$emit("itemAdded", this.newItem);
      this.$emit("overlayClicked");
      this.newItem = {
        itemId: "",
        modelOrPart: "",
        price: "",
        quantity: "",
        description: "",
        dutyPercentage: 0,
        markUpPercentage: 0,
        files: [],
      };
    },
    handleImageSelect(files) {
      this.newItem.files = files;
    },
    // resetForm() {
    //   this.newStatus = {
    //     title: "",
    //     date: "",
    //   };
    //   this.$emit("overlayClicked");
    // },
  },
  components: {
    ImageUploadModal,
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>